























































































import { Component, Vue } from 'vue-property-decorator';
import data from '../data/data';

@Component({
  components: {},
})
export default class Gallery extends Vue {
  gallery = data.gallery.map((image) => {
    return { ...image, src: require(`../assets/images/${image.src}`) };
  });
}
